import React from 'react';
import NotificationIcon from './notification/NotificationIcon';
import ProfileImage from './profile/ProfileImage';
import {useNavigate} from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* Header */}
      <div className='justify-between items-center md:flex hidden w-full bg-white p-2 rounded-lg'>
        {/* <div className='px-6'>
          <p className='text-base font-poppinsRegular'>Hi, {firstName} 👋 </p>
          <p className='text-sm text-[#878987] font-poppinsRegular'>
            {' '}
            Welcome back!
          </p>
        </div> */}

        <div>
          <p className='text-primary uppercase text-base font-semibold pl-1'>
            Dashboard
          </p>
        </div>
        <div className='flex items-center gap-4'>
          {/* Notification */}
          <NotificationIcon onClick={() => navigate('../admin/messages')} />
          {/* Profile Image */}
          <ProfileImage onClick={() => ''} />
        </div>
      </div>
    </>
  );
};

export default Header;
