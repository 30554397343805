/* eslint-disable react-hooks/exhaustive-deps */
import {ChevronDown, PencilSm} from '../../../assets/images';
import Button from '../../../components/dashboard/profile/button';
import Input from '../../../components/dashboard/profile/input';
import Select from '../../../components/dashboard/profile/select';
import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from '../../../assets/styles/modals/ConfirmModal.module.css';
import {CloseCircle} from 'iconsax-react';
import {axiosError, extractParentheses, removeCurlyBrackets,} from '../../../components/Helpers';
import DefaultProductImage from '../../../assets/images/DefaultProductImage.png';
import {VendorServices} from '../../../redux/services/vendor/vendor';
/* eslint-disable react-hooks/exhaustive-deps */
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';
import {useVendor} from '../../../app/hooks';
import {toast} from 'react-toastify';

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: (
    formDataAddImg: any,
    name: string,
    description: string,
    subCategory: string,
    quantity: string,
    discount: string,
    superCategory: string,
    category: string,
    price: string,
    location: string,
    brand: string,
    warranty: string,
    minimumStock: string,
    dimension: string,
    volume: string,
    weight: string,
    count: string,
    commission: string,
    color: string,
    size: string,
    shipFromAbroad: boolean,
    condition: string,
    setIsUploading: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
}

export interface IFormData {
  name: string;
  description: string;
  price: string;
  quantity: string;
  discount: string;
  location: string;
  superCategory: string;
  category: string;
  subCategory: string;
  productImg: File | null;
  brand: string;
  warranty: string;
  minimumStock: string;
  additionalImg: FileList | null;
  dimension: string;
  volume: string;
  weight: string;
  count: string;
  commission: string;
  color: string;
  size: string;
  shipFromAbroad: boolean;
  condition: string;
}
const ProductMain = ({setIsOpen, handleSubmit}: IModal) => {
  const [step, setStep] = useState<number>(1);

  const handleConfirm = () => {
    setStep(4);
  };
  const backUpload = () => {
    setStep(3);
  };

  const handleUpload = () => {
    setStep(3);
  };
  const backDetails = () => {
    setStep(1);
  };

  const handleMeasurement = () => {
    setStep(2);
  };
  const backMeasurement = () => {
    setStep(2);
  };

  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const [superCategoryOption, setSuperCategoryOption] = useState<any[]>([]);
  const [categoryOption, setCategoryOption] = useState<any[]>([]);
  const [subCategoryOption, setSubCategoryOption] = useState<any[]>([]);
  const [locationOption, setLocationOption] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    description: '',
    subCategory: '',
    productImg: null,
    quantity: '',
    discount: '',
    superCategory: '',
    category: '',
    price: '',
    location: '',
    brand: '',
    warranty: '',
    minimumStock: '',
    additionalImg: null,
    dimension: '',
    volume: '',
    weight: '',
    count: '',
    commission: '',
    color: '',
    size: '',
    shipFromAbroad: false,
    condition: '',
  });

  const conditionOptions = [
    {
      name: 'New',
    },
    {
      name: 'PreOwned',
    },
  ];

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target;
    // Validate phoneNumber separately
    if (name === 'price' || name === 'quantity' || name === 'minimumStock') {
      const numericValueNUmber = value.replace(/\D/g, ''); // Remove non-numeric characters
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValueNUmber,
      }));
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Handle Category
  const handleSuperCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const superCategory = event.target.value;
    const selectedSuperCategory = superCategoryOption.find(
      (c) => c.name === superCategory
    );

    if (selectedSuperCategory) {
      setCategoryOption(selectedSuperCategory.categories);
      setFormData((prevData) => ({
        ...prevData,
        superCategory,
        category: '',
        subCategory: '',
      }));
    } else {
      setCategoryOption([]);
      setSubCategoryOption([]);
      setFormData((prevData) => ({
        ...prevData,
        supperCategory: '',
        category: '',
        subCategory: '',
      }));
    }
  };
  // Handle Category
  // console.log('Commision', formData.commission);
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const category = event.target.value;
    const selectedCategory = categoryOption.find((c) => c.name === category);
    if (selectedCategory) {
      setFormData((prevData) => ({
        ...prevData,
        commission: selectedCategory.commission,
      }));
    }

    if (selectedCategory) {
      setSubCategoryOption(selectedCategory.subCategories);
      setFormData((prevData) => ({...prevData, category, subCategory: ''}));
    } else {
      setSubCategoryOption([]);
      setFormData((prevData) => ({...prevData, category: '', subCategory: ''}));
    }
  };

  // Handle Sub Category
  const handleSubCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({...prevFormData, subCategory: value}));
  };
  const handleLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({...prevFormData, location: value}));
  };
  const handleConditionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({...prevFormData, condition: value}));
  };

  // Get Category
  useEffect(() => {
    const getSuperCategory = async () => {
      try {
        const response = await VendorServices.getSuperCategory();
        // console.log(response);
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setSuperCategoryOption(res.rows);
        }
      } catch (error: any) {
        // console.log(error);
        axiosError(error.response);
        // setIsLoading(false);
      }
    };
    getSuperCategory();
  }, []);

  // Get Location
  useEffect(() => {
    const getLocation = async () => {
      try {
        const response = await VendorServices.getLocation();
        // console.log('Location', response);
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setLocationOption(res.rows);
        }
      } catch (error: any) {
        // console.log(error);
        axiosError(error.response);
        // setIsLoading(false);
      }
    };
    getLocation();
  }, []);

  //  Handle Multi Image Upload
  const handleMultiImageUpload = (event: any) => {
    const files = event.target.files;

    if (!files || files.length === 0) {
      return;
    }

    setFormData((prevFormData) => {
      // Log the current state before updating
      console.log('Before State Update:', prevFormData);

      // Update the state with the selected files
      // Log the updated state
      // console.log('After State Update:', newFormData);

      return {
        ...prevFormData,
        additionalImg: files,
      };
    });
    // console.log(formData.images);

    if (files) {
      // Convert FileList to an array
      const selectedFiles = Array.from(files).slice(0, 3);

      // Validate that all files are images and less than 1MB
      const validFiles = selectedFiles.filter((file: any) => {
        if (!file.type.startsWith('image/')) {
          toast.error('Only image files are allowed.');
          return false;
        }
        if (file.size > 1 * 1024 * 1024) {
          toast.error('File size should be less than 1MB.');
          return false;
        }
        return true;
      });

      if (validFiles.length > 0) {
        // Generate preview URLs for valid files
        const urls = validFiles.map((file: any) => URL.createObjectURL(file));
        setPreviewImages(urls);
        toast.error(null);
      } else {
        setPreviewImages([]);
      }
    }
  };

  const [dimensionFormData, setDimensionFormData] = useState({
    length: '',
    width: '',
    height: '',
  });
  const [dimensionOption, setDimensionOption] = useState<any[]>([]);
  const [volumeOption, setVolumeOption] = useState<any[]>([]);
  const [weightOption, setWeightOption] = useState<any[]>([]);
  const [countOption, setCountOption] = useState<any[]>([]);

  const handleDimension = (e: any) => {
    const {name, value} = e.target;
    setDimensionFormData((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const token = useVendor().token;
  // Get Measurement
  useEffect(() => {
    getDimension();
    getWeight();
    getVolume();
    getCount();
  }, []);

  const getVolume = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'volume');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setVolumeOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getWeight = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'weight');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setWeightOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getCount = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'count');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setCountOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getDimension = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(
        token,
        'dimension'
      );
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setDimensionOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const allDimension = removeCurlyBrackets(JSON.stringify(dimensionFormData));

  const [dimensionUnit, setDimensionUnit] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('');
  const [weightUnit, setWeightUnit] = useState('');
  const [countUnit, setCountUnit] = useState('');

  const [measurementFormData, setMeasurementFormData] = useState({
    dimension: '',
    weight: '',
    volume: '',
    count: '',
  });

  const handleSelectMeasurement = (e: any) => {
    const {name, value} = e.target;
    setMeasurementFormData((p) => ({
      ...p,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(measurementFormData.dimension);
    setDimensionUnit(extractedUnit);
    // setFormData((p) => ({
    //   ...p,
    //   dimension: allDimension + dimensionUnit,
    // }));
  }, [measurementFormData.dimension]);

  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(measurementFormData.volume);
    setVolumeUnit(extractedUnit);
    // setFormData((p) => ({
    //   ...p,
    //   volume: formData.volume + volumeUnit,
    // }));
  }, [measurementFormData.volume]);
  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(measurementFormData.weight);
    setWeightUnit(extractedUnit);
  }, [measurementFormData.weight]);
  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(measurementFormData.count);
    setCountUnit(extractedUnit);
  }, [measurementFormData.count]);

  const realDimension = `${allDimension} ${dimensionUnit}`;
  const realVolume = `${formData.volume} ${volumeUnit}`;
  const realWeight = `${formData.weight} ${weightUnit}`;
  const realCount = `${formData.count} ${countUnit}`;

  console.log(realVolume);
  // Update Product
  const submitHandler = async () => {
    // ... (other logic)

    // Call handleSubmit and wait for it to complete
    await handleSubmit(
      formData.additionalImg,
      formData.name,
      formData.description,
      formData.subCategory,
      formData.quantity,
      formData.discount,
      formData.superCategory,
      formData.category,
      formData.price,
      formData.location,
      formData.brand,
      formData.warranty,
      formData.minimumStock,
      realDimension,
      realVolume,
      realWeight,
      realCount,
      formData.commission,
      formData.color,
      formData.size,
      formData.shipFromAbroad,
      formData.condition,
      setIsUploading
    );

    // ... (other logic)
  };
  // console.log(formData.dimension);

  return (
    <div>
      <div className={styles.confirmDeleteBG} />
      <div className={`${styles.productCentered} + shadow-lg`}>
        <div className={styles.productModal}>
          <div className={styles.modalContent}>
            {/* Stepper */}
            <div className='w-full py-6 relative sm:block hidden'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex items-center justify-evenly w-full gap-x-[1rem]'>
                <div className='flex items-center justify-center gap-x-2'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 1 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    1
                  </div>
                  <div>
                    <span
                      className={
                        step === 1
                          ? 'text-primary font-bold'
                          : 'text-[#B3B3B3] font-bold'
                      }>
                      Details
                    </span>
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 2 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    2
                  </div>
                  <div>
                    <span
                      className={
                        step === 2
                          ? 'text-primary font-bold'
                          : 'text-[#B3B3B3] font-bold'
                      }>
                      Specifications
                    </span>
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 3 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    3
                  </div>
                  <div>
                    <span
                      className={
                        step === 3
                          ? 'text-primary font-bold'
                          : 'text-[#B3B3B3] font-bold'
                      }>
                      Image
                    </span>
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 4 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    4
                  </div>
                  <div>
                    <span
                      className={
                        step === 4
                          ? 'text-primary font-bold'
                          : 'text-[#B3B3B3] font-bold'
                      }>
                      Upload
                    </span>
                  </div>
                  <div>
                    <hr className=' flex-grow border-t border-primary' />
                  </div>
                </div>
              </div>
            </div>

            {/* Stepper Small */}
            <div className='w-[80%] m-auto py-6 relative block sm:hidden '>
              <div
                className='absolute top-2 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex items-center justify-center w-full gap-x-[1rem]'>
                <div className='flex items-center justify-center gap-x-2'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 1 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    1
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 2 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    2
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 3 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    3
                  </div>
                </div>
                <div className='flex items-center justify-center gap-x-3'>
                  <div
                    className={`rounded-full w-[1.5rem] h-[1.5rem] ${
                      step === 4 ? 'bg-primary' : 'bg-[#B3B3B3]'
                    }  text-white flex items-center justify-center font-bold`}>
                    4
                  </div>
                  <div>
                    <hr className=' flex-grow border-t border-primary' />
                  </div>
                </div>
              </div>
            </div>

            {/* Main */}
            <div className='h-full'>
              {step === 1 && (
                <>
                  <div className='h-full overflow-auto'>
                    <div className='mt-[1rem]'>
                      <label>
                        <input
                          type='checkbox'
                          checked={formData.shipFromAbroad}
                          onChange={handleInputChange}
                          name='shipFromAbroad'
                        />
                        <span className='pl-2'>
                          Tick only if you want to ship the product from abroad
                        </span>
                      </label>
                    </div>
                    <div className='grid lg:grid-cols-2 grid-cols-1 place-items-baseline justify-items-center w-[80%] m-auto space-y-[2rem] mt-[1rem] gap-6'>
                      {/* <Select label='BUSINESS TYPE' icon={ChevronDown} /> */}
                      <div className='w-full'>
                        <Select
                          label='SUPER CATEGORY'
                          icon={ChevronDown}
                          name='superCategory'
                          handleChange={handleSuperCategoryChange}
                          options={superCategoryOption}
                          valueField={formData.superCategory}
                          required
                        />
                      </div>
                      <div className='w-full'>
                        <Select
                          label='CATEGORY'
                          icon={ChevronDown}
                          name='category'
                          handleChange={handleCategoryChange}
                          options={categoryOption}
                          valueField={formData.category}
                          required
                        />
                      </div>
                      <div className='w-full'>
                        <Select
                          label='SUB CATEGORY'
                          icon={ChevronDown}
                          handleChange={handleSubCategoryChange}
                          options={subCategoryOption}
                          name='subCategory'
                          valueField={formData.subCategory}
                          required
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label='PRODUCT NAME'
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='name'
                          valueField={formData.name}
                          required
                          placeholder='Enter Product Name'
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label='BRAND NAME'
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='brand'
                          valueField={formData.brand}
                          required
                          placeholder='Enter Brand Name'
                        />
                      </div>

                      <div className='w-full'>
                        <Input
                          label='NUMBER IN STOCK'
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='quantity'
                          valueField={formData.quantity}
                          required
                          placeholder='300'
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label={`PRICE ₦‎`}
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='price'
                          valueField={formData.price}
                          required
                          placeholder='500,000'
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label={`DISCOUNT %`}
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='discount'
                          valueField={formData.discount}
                          // required
                          placeholder='1.2'
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label={`Minimum Stock`}
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='minimumStock'
                          valueField={formData.minimumStock}
                          required
                          placeholder='6'
                        />
                      </div>
                      <div className='w-full'>
                        <Select
                          label='LOCATION'
                          icon={ChevronDown}
                          handleChange={handleLocationChange}
                          options={locationOption}
                          name='Location'
                          valueField={formData.location}
                          required
                        />
                      </div>
                      <div className='w-full'>
                        <Input
                          label={`Warranty`}
                          icon={PencilSm}
                          pattern=''
                          handleChange={handleInputChange}
                          name='warranty'
                          valueField={formData.warranty}
                          placeholder='N/A'
                        />
                      </div>
                      <div className='w-full'>
                        <Select
                          label='CONDITION'
                          icon={ChevronDown}
                          handleChange={handleConditionChange}
                          options={conditionOptions}
                          name='condition'
                          valueField={formData.condition}
                          required
                        />
                      </div>


                    </div>
                    <div className='w-[80%] mx-auto pt-10'>
                      <div className='top-2 left-2 flex flex-col items-start'>
                          <span className='text-[#9A9AAA] font-poppinsMedium font-medium pb-2'>
                            PRODUCT DESCRIPTION
                            <span className='text-red-500 text-base pl-1'>
                              *
                            </span>
                          </span>
                        <div className='w-full rounded-md border-[2px] border-gray-400 px-3'>
                            <textarea
                                className='outline-none w-full h-full p-3 font-light text-xs'
                                name='description'
                                value={formData.description}
                                placeholder='Description'
                                cols={10}
                                rows={10}
                                style={{
                                  height: '100%',
                                }}
                                onChange={(e: any) => handleInputChange(e)}
                                required
                            />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row justify-center pt-2 lg:px-28 my-6'>
                      <Button
                        handleSubmit={handleMeasurement}
                        type='button'
                        className='text-white font-bold h-[3em]'
                        disabled={!formData.description ? true : false}>
                        NEXT
                      </Button>
                    </div>
                  </div>
                </>
              )}

              {step === 2 && (
                <>
                  <div className='h-full overflow-auto pt-8'>
                    <div className='w-[80%] m-auto'>
                      <div className='flex md:flex-row flex-col gap-1 items-start pb-6'>
                        <h2 className='font-poppinsSemibold text-black '>
                          Color and Size
                        </h2>
                        <p className='text-gray-400 text-xs'> optional</p>
                      </div>
                      <div className='flex flex-col w-full gap-6 pb-4'>
                        <div className='w-full flex lg:flex-row flex-col justify-between items-center gap-10'>
                          <div className='w-full'>
                            <Input
                              label={`Color`}
                              icon={PencilSm}
                              pattern=''
                              handleChange={handleInputChange}
                              name='color'
                              valueField={formData.color}
                              placeholder=''
                            />
                          </div>
                          <div className='w-full'>
                            <Input
                              label={`Size`}
                              icon={PencilSm}
                              pattern=''
                              handleChange={handleInputChange}
                              name='size'
                              valueField={formData.size}
                              placeholder=''
                            />
                          </div>
                        </div>
                      </div>

                      <div className='flex lg:flex-row flex-col gap-1 items-start pb-6'>
                        <h2 className='font-poppinsSemibold text-black '>
                          {' '}
                          Unit of Measurement{' '}
                        </h2>
                        <p className='text-gray-400 text-xs'> optional</p>
                      </div>
                      <div className='flex flex-col w-full gap-6'>
                        <div className='w-full flex lg:flex-row flex-col justify-between items-center gap-10'>
                          {/* large Width */}
                          <div className='lg:w-[60%] w-full'>
                            <Select
                              label='DIMENSION'
                              icon={ChevronDown}
                              handleChange={handleSelectMeasurement}
                              options={dimensionOption}
                              name='dimension'
                              valueField={measurementFormData.dimension}
                              // required
                            />
                          </div>
                          {/* small Width */}
                          <div className='lg:w-[40%] w-full'>
                            {/* Length && Width &  ... */}
                            <div className='flex flex-row gap-4 justify-between'>
                              <Input
                                label={`Length`}
                                icon={PencilSm}
                                pattern=''
                                handleChange={handleDimension}
                                name='length'
                                valueField={dimensionFormData.length}
                                // required
                                placeholder='40'
                              />
                              <Input
                                label={`Width`}
                                icon={PencilSm}
                                pattern=''
                                handleChange={handleDimension}
                                name='width'
                                valueField={dimensionFormData.width}
                                // required
                                placeholder='5'
                              />
                              <Input
                                label={`Height`}
                                icon={PencilSm}
                                pattern=''
                                handleChange={handleDimension}
                                name='height'
                                valueField={dimensionFormData.height}
                                // required
                                placeholder='10'
                              />
                            </div>
                          </div>
                        </div>
                        <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
                          {/* large Width */}
                          <div className='lg:w-[60%] w-full'>
                            <Select
                              label='VOLUME'
                              icon={ChevronDown}
                              handleChange={handleSelectMeasurement}
                              options={volumeOption}
                              name='volume'
                              valueField={measurementFormData.volume}
                              // required
                            />
                          </div>
                          {/* small Width */}
                          <div className='lg:w-[40%] w-full'>
                            {/* Length && Width &  ... */}

                            <Input
                              label=''
                              icon={PencilSm}
                              pattern=''
                              handleChange={handleInputChange}
                              name='volume'
                              valueField={formData.volume}
                              // required
                              placeholder='5'
                            />
                          </div>
                        </div>
                        <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
                          {/* large Width */}
                          <div className='lg:w-[60%] w-full'>
                            <Select
                              label='WEIGHT'
                              icon={ChevronDown}
                              handleChange={handleSelectMeasurement}
                              options={weightOption}
                              name='weight'
                              valueField={measurementFormData.weight}
                              // required
                            />
                          </div>
                          {/* small Width */}
                          <div className='lg:w-[40%] w-full'>
                            {/* Length && Width &  ... */}

                            <Input
                              label=''
                              icon={PencilSm}
                              pattern=''
                              handleChange={handleInputChange}
                              name='weight'
                              valueField={formData.weight}
                              // required
                              placeholder='5'
                            />
                          </div>
                        </div>
                        <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
                          {/* large Width */}
                          <div className='lg:w-[60%] w-full'>
                            <Select
                              label='COUNT'
                              icon={ChevronDown}
                              handleChange={handleSelectMeasurement}
                              options={countOption}
                              name='count'
                              valueField={measurementFormData.count}
                              // required
                            />
                          </div>
                          {/* small Width */}
                          <div className='lg:w-[40%] w-full'>
                            {/* Length && Width &  ... */}

                            <Input
                              label=''
                              icon={PencilSm}
                              pattern=''
                              handleChange={handleInputChange}
                              name='count'
                              valueField={formData.count}
                              // required
                              placeholder='5'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='sm:flex items-center justify-center gap-x-4 hidden pt-2 my-6'>
                      <Button
                        handleSubmit={backDetails}
                        type='button'
                        className='text-white font-bold  h-[3em] bg-gray-300'>
                        BACK
                      </Button>
                      <Button
                        handleSubmit={handleUpload}
                        // handleSubmit={() => console.log(formData)}
                        type='button'
                        className='text-white font-bold h-[3em]'>
                        NEXT
                      </Button>
                    </div>
                    {/* Button Mobile */}
                    <div className='flex flex-row items-center justify-evenly w-full sm:hidden pt-2 my-6'>
                      <button
                        onClick={backDetails}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-gray-300 rounded-md'>
                        BACK
                      </button>
                      <button
                        onClick={handleUpload}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-primary rounded-md'>
                        NEXT
                      </button>
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <div className='h-full overflow-auto  pt-8'>
                    <div className='flex flex-col items-center justify-center space-y-[2rem]'>
                      <p className='text-lg'>
                        Instruction:
                        <span className='pl-4 text-red-400 sm:text-base text-sm'>
                          Please upload maximum of 3 images.
                        </span>
                      </p>
                      <p className='text-lg'>
                        Hint:
                        <span className='pl-4 text-black sm:text-base text-sm'>
                          <span className='font-poppinsMedium text-blue-600 pr-4'>
                            (Laptop/Desktop Users)
                          </span>
                          Press and hold Ctrl key on your keyboard to mark
                          multiple images.
                        </span>
                      </p>

                      {/* Upload */}
                      <div className='flex flex-row justify-center lg:gap-14 gap-10 w-full lg:px-24'>
                        {/* Product Images */}

                        <div className=''>
                          <div className=' flex flex-row items-end relative'>
                            <div className='w-[400px] h-[400px] text-center object-cover'>
                              {previewImages.length > 0 && (
                                <Slider slideImage={previewImages} />
                              )}
                              {previewImages.length === 0 && (
                                <img
                                  src={DefaultProductImage}
                                  alt='profile-def'
                                  className=' w-full h-full object-contain'
                                />
                              )}
                            </div>
                          </div>
                          <div
                            className='bg-primary py-2 text-white rounded cursor-pointer text-center px-8 relative'
                            onClick={handleMultiImageUpload}>
                            <p>Pick Images</p>
                            <input
                              type='file'
                              accept='image/*'
                              multiple
                              style={{opacity: 0}}
                              onChange={handleMultiImageUpload}
                              className='absolute z-10 right-[0%] bottom-0 cursor-pointer w-full h-full border-2'
                              title='Upload'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='sm:flex items-center justify-center gap-x-4 hidden pt-2 my-6'>
                      <Button
                        handleSubmit={backMeasurement}
                        type='button'
                        className='text-white font-bold  h-[3em] bg-gray-300'>
                        BACK
                      </Button>
                      <Button
                        handleSubmit={handleConfirm}
                        type='button'
                        className='text-white font-bold h-[3em]'>
                        NEXT
                      </Button>
                    </div>
                    {/* Button Mobile */}
                    <div className='flex flex-row items-center justify-evenly w-full sm:hidden pt-2 my-6'>
                      <button
                        onClick={backMeasurement}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-gray-300 rounded-md'>
                        BACK
                      </button>
                      <button
                        onClick={handleConfirm}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-primary rounded-md'>
                        NEXT
                      </button>
                    </div>
                  </div>
                </>
              )}

              {step === 4 && (
                <>
                  <div className='h-full overflow-auto'>
                    <div className='flex flex-col items-center justify-center space-y-[2rem] mt-[4rem] overflow-auto'>
                      <div>
                        <div>
                          <span className='text-md font-normal'>
                            Please confirm your product details. Click on the
                            Upload Product button to upload
                          </span>
                        </div>
                      </div>
                      <div className='flex flex-col items-center justify-center space-y-[2rem] mt-[4rem]'>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Super Category</span>
                          <span>{formData.superCategory}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Category</span>
                          <span>{formData.category}</span>
                        </div>

                        <div className='flex items-center justify-center gap-x-3'>
                          <span>subCategory</span>
                          <span>{formData.subCategory}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Product Name</span>
                          <span>{formData.name}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Product Description</span>
                          <span>{formData.description}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Product Quantity</span>
                          <span>{formData.quantity}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Product Price</span>
                          <span>{formData.price}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Discount %</span>
                          <span>{formData.discount}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Warranty</span>
                          <span>{formData.warranty}</span>
                        </div>
                        <div className='flex items-center justify-center gap-x-3'>
                          <span>Location</span>
                          <span>{formData.location}</span>
                        </div>
                      </div>
                    </div>
                    <div className='sm:flex items-center justify-center gap-x-4 hidden pt-2 my-6'>
                      <Button
                        handleSubmit={backUpload}
                        type='button'
                        className='text-white font-bold  h-[3em] bg-[#B3B3B3]'>
                        BACK
                      </Button>
                      <Button
                        handleSubmit={submitHandler}
                        type='button'
                        className='text-white font-bold h-[3em] '>
                        {isUploading
                          ? 'Uploading Product...'
                          : 'Upload Product'}
                      </Button>
                    </div>
                    {/* Button Mobile */}
                    {/* Button Small */}
                    <div className='flex flex-row items-center justify-evenly w-full sm:hidden pt-2 my-6'>
                      <button
                        onClick={backUpload}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-gray-300 rounded-md'>
                        BACK
                      </button>
                      <button
                        onClick={submitHandler}
                        type='button'
                        className='text-white font-bold py-2 px-4 bg-primary rounded-md'>
                        {isUploading
                          ? 'Uploading Product...'
                          : 'Upload Product'}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMain;

export interface ISlider {
  slideImage: any;
}
const Slider = ({slideImage}: ISlider) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slideImage.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slideImage.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: any) => {
    setCurrentIndex(slideIndex);
  };

  // Auto slide change every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 6000);

    return () => clearInterval(interval);
  }, [currentIndex]); // Run when currentIndex changes

  return (
    <div className='w-full h-full relative group cursor-pointer'>
      {slideImage.map((slide: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${slide})`,
            opacity: index === currentIndex ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out', // CSS transition
          }}
          className='w-full h-full rounded-md bg-contain duration-500 bg-no-repeat absolute top-0 left-0 object-contain'></div>
      ))}
      {/* Left Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex w-full justify-center '>
        <div className='flex bottom-0 justify-center py-2 absolute'>
          {slideImage.map((_slide: any, slideIndex: any) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className='text-2xl cursor-pointer'>
              <RxDotFilled
                className={`${
                  slideIndex === currentIndex ? 'text-primary' : 'text-gray-300'
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
