/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react';
import Spinner from '../spinner/Spinner';
import {BiCheckCircle} from 'react-icons/bi';
import {COLORS} from '../../assets/color';
import moment from 'moment';
import {CloseCircle} from 'iconsax-react';

export interface GiftCard {
  id: any;
  productImg: string;
  title: string;
  code: string;
  name: string;
  email: string;
  amount: string;
  date: string;
  status: string;
  isActive?: boolean;
  orderedGifts: [
    {
      id: string;
      recipientEmail: string;
      recipientName: string;
      code: string;
      status: string;
      createdAt: string;
    }
  ];
}
interface ITransactionTable {
  transactions: any[];
  isActionLoading?: boolean;
}
const GiftCardTransactionTable = ({
  transactions,
  isActionLoading,
}: ITransactionTable) => {
  return (
    <>
      <div>
        {isActionLoading && <Spinner />}
        <table className=' mt-2 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10'>Title</th>
              <th className='w-10'>Name</th>
              <th className='w-10'>Email</th>
              <th className='w-10'>Purchase Amount</th>
              <th className='w-10'>Date</th>
              <th className='w-10'>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {transactions.length === 0 ? (
              <tr>
                <td className='text-center py-6' colSpan={8}>
                  <span className='text-xl text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            ) : (
              transactions.map((orderGift) => (
                <tr
                  className='overflow-x-auto h-[3.125rem] mb-4'
                  key={orderGift.id}>
                  <td className='w-10 text-start'>
                    <div className='flex flex-row gap-10 items-center'>
                      <img
                        src={orderGift.transaction.productImg} // Use transaction reference
                        alt={orderGift.transaction.title}
                        className='w-20 h-20 rounded-lg'
                      />
                      <div className='flex flex-col justify-start items-start'>
                        <h3 className='font-bold text-sm'>
                          {orderGift.transaction.title}
                        </h3>
                        <p className='text-gray-500 text-xs'>
                          {orderGift.code}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className='w-10 text-center'>
                    {orderGift.recipientName}
                  </td>
                  <td className='w-10 text-center'>
                    {orderGift.recipientEmail}
                  </td>
                  <td className='w-10 text-center'>
                    {orderGift.transaction.amount}
                  </td>
                  <td className='w-10 text-center'>
                    {moment(orderGift.createdAt).format('ll')}
                  </td>
                  {/* Status */}
                  <td className='w-16'>
                    <div className='flex flex-row justify-evenly items-center h-10'>
                      <div
                        className={
                          orderGift.transaction.status === 'active'
                            ? 'px-2 py-1 bg-[#E6FCE5] text-success flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'
                            : 'px-2 py-1 bg-[#FEEFEE] text-red-500 flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'
                        }>
                        {orderGift.transaction.status === 'active' ? (
                          <BiCheckCircle
                            color={COLORS.success}
                            size={22}
                            style={{cursor: 'pointer'}}
                          />
                        ) : (
                          <CloseCircle
                            color={COLORS.error}
                            size={22}
                            style={{cursor: 'pointer'}}
                          />
                        )}
                        <span>{orderGift.transaction.status}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GiftCardTransactionTable;
