/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {useEffect, useState} from 'react';
import {AdminServices} from '../../../redux/services/admin/admin';
import {IVendor} from '../../../components/interfaces/vendors';
import {axiosError} from '../../../components/Helpers';
import {Zoom, toast} from 'react-toastify';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import VendorUpdateTable from '../../../components/table/VendorUpdateTable';
import {useAdmin} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';

const UserManagementVendor = ({show}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  const token = useAdmin().adminToken;
  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  const keys = ['firstName', 'email', 'phone'];
  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Get Vendors
  useEffect(() => {
    getVendors();
  }, []);

  const getVendors = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.allVendors(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data.vendors;
        setVendors(res.rows);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // Reset Vendor
  const [showView, setShowView] = useState(false);
  const handleReset = async (id: string, vendorEmail: string) => {
    const vendorId = id;
    setIsActionLoading(true);
    const data = {
      email: vendorEmail,
    };
    try {
      const response = await AdminServices.resetVendor(token, vendorId, data);
      setIsActionLoading(false);
      if (response.data.status === 'success') {
        const res = response.data.data;
        toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          transition: Zoom,
          closeOnClick: false,
          hideProgressBar: true,
          draggable: false,
          style: {width: '400px'},
        });
        setShowView(false);
        setIsActionLoading(false);
        await getVendors();
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsActionLoading(false);
    }
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Vendor Management</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>VENDOR MANAGEMENT</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => navigate('../admin/messages')} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <VendorUpdateTable
                vendors={search(vendors)}
                showView={showView}
                setShowView={setShowView}
                handleReset={handleReset}
                isActionLoading={isActionLoading}
              />
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default UserManagementVendor;
