export const generateImageWithAmount = (
  imageSrc: string,
  amount: string
): Promise<Blob | null> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      resolve(null);
      return;
    }

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = imageSrc;

    img.onload = () => {
      // Set canvas size same as the image
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image
      ctx.drawImage(img, 0, 0);

      // Set text styles
      ctx.font = 'bold 40px Arial';
      ctx.fillStyle = 'white';
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 3;
      ctx.textAlign = 'right';

      // Position of the amount on the image
      const x = canvas.width - 50;
      const y = canvas.height - 50;

      // Draw text with shadow effect
      ctx.strokeText(`NGN ${Number(amount).toLocaleString()}`, x, y);
      ctx.fillText(`NGN ${Number(amount).toLocaleString()}`, x, y);

      // Convert canvas to Blob
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/png');
    };

    img.onerror = () => resolve(null);
  });
};
