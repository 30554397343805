/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {IOverviewCardProps} from '../../../components/dashboard/OverviewCard';
import {Helmet} from 'react-helmet';
import {useEffect, useState} from 'react';
import {BarChart, Title} from '@tremor/react';
import Picker from '../../../components/forms/inputs/selectInput/Picker';
import {SalesData, resolutionData} from '../../../utils/data';
import {axiosError, numberFormat} from '../../../components/Helpers';
import Resolution from './Resolution';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {AdminServices} from '../../../redux/services/admin/admin';
import {useAdmin} from '../../../app/hooks';
import {format, getWeek, parseISO, startOfWeek} from 'date-fns';
import Spinner from '../../../components/spinner/Spinner';
import {IoMdWallet} from 'react-icons/io';
import {Icon} from '@iconify/react';
import {useNavigate} from 'react-router-dom';

interface FilteredData {
  name: string;
  sales: number;
}
const Accounting = () => {
  const valueFormatter = (number: number) =>
    `${Intl.NumberFormat('en-NG').format(number).toString()}`;

  const token = useAdmin().adminToken;
  const [settlements, setSettlements] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [filteredData, setFilteredData] = useState<FilteredData[]>([]);
  const [filter, setFilter] = useState<'Monthly' | 'Weekly' | 'Daily'>(
    'Monthly'
  );

  // Get Settlements
  useEffect(() => {
    getSettlements();
    getBusinessWallet();
  }, [filter]);

  const getSettlements = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getSettlements(token);
      setIsLoading(false);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        setSettlements(res);
        setSalesData(res);
        filterData(res, filter);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  const options = [
    {value: 'Monthly', label: 'Monthly'},
    {value: 'Weekly', label: 'Weekly'},
    {value: 'Daily', label: 'Daily'},
  ];

  const filterData = (
    data: SalesData[],
    filterType: 'Monthly' | 'Weekly' | 'Daily'
  ) => {
    const formattedData = data.map((item) => {
      const date = parseISO(item.createdAt);
      let formattedDate = '';
      if (filterType === 'Monthly') {
        formattedDate = format(date, 'MMMM'); // Format as month name
      } else if (filterType === 'Weekly') {
        formattedDate = `Week ${getWeek(startOfWeek(date))}`; // Format as week number
      } else if (filterType === 'Daily') {
        formattedDate = format(date, 'EEEE'); // Format as day of the week name
      }
      return {
        ...item,
        createdAt: formattedDate,
      };
    });

    const aggregatedData = formattedData.reduce((acc, item) => {
      const found = acc.find((i) => i.name === item.createdAt);
      if (found) {
        found.sales += Number(item.amount);
      } else {
        acc.push({name: item.createdAt, sales: Number(item.amount)});
      }
      return acc;
    }, [] as FilteredData[]);

    setFilteredData(aggregatedData);
  };

  const handleFilterChange = (value: string) => {
    const filterType = value as 'Monthly' | 'Weekly' | 'Daily';
    setFilter(filterType);
    filterData(salesData, filterType);
  };

  const PaidSettlement = settlements?.filter(
    (item: any) => item.status === 'paid'
  );
  const TotalPayoutAmount = PaidSettlement.reduce(
    (acc: any, sum: any) => acc + Number(sum.amount),
    0
  );
  const navigate = useNavigate();

  // Get Business Wallet
  const [walletBusiness, setWalletBusiness] = useState<any>({});
  const getBusinessWallet = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getBusinessWallet(token);
      setIsLoading(false);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        setWalletBusiness(res);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  // console.log(filteredData, 'filterData');
  const OverviewData: IOverviewCardProps[] = [
    {
      icon: <IoMdWallet size={22} />,
      title: 'Total Income',
      amount: walletBusiness.totalIncome,
    },
    {
      icon: <Icon icon='healthicons:money-bag' fontSize={24} />,
      title: 'Total Commission',
      amount: walletBusiness.totalCommission,
    },
    {
      icon: <Icon icon='solar:bill-check-bold' fontSize={24} />,
      title: 'Total Completed Order',
      value: settlements?.length,
    },
    {
      icon: <Icon icon='mingcute:bill-fill' fontSize={24} />,
      title: 'Total Payout',
      amount: TotalPayoutAmount,
    },
  ];
  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {isLoading && <Spinner />}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Accounting</title>
        </Helmet>
        <div className='pb-4'>
          {/* Header */}
          <div className='justify-between items-center md:flex hidden w-full bg-white p-2 rounded-lg'>
            {/* <div className='px-6'>
          <p className='text-base font-poppinsRegular'>Hi, {firstName} 👋 </p>
          <p className='text-sm text-[#878987] font-poppinsRegular'>
            {' '}
            Welcome back!
          </p>
        </div> */}

            <div>
              <p className='text-primary uppercase text-base font-semibold pl-1'>
                Accounting
              </p>
            </div>
            <div className='flex items-center gap-4'>
              {/* Search */}
              <div className=' flex flex-row items-center w-full justify-between'>
                {/* Search Input  */}
              </div>
              {/* Notification */}
              <NotificationIcon onClick={() => navigate('../admin/messages')} />
              {/* Profile Image */}
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </div>
        {/* {query} */}
        <div className='py-4 flex flex-row xl:justify-between items-center 2xl:gap-20 lg:gap-10 gap-2'>
          <OverviewCard datasets={OverviewData} />
        </div>

        {/* Chart nad Card */}
        <div className='grid 2xl:grid-cols-2 gap-4 grid-cols-1 pt-4'>
          {/* Chart */}
          <div className='col-span-1'>
            <div className='w-full bg-white rounded-lg h-auto p-6 text-xs'>
              <div>
                <Title className='text-black font-poppinsMedium'>
                  Sales Performance
                </Title>
                <div className='flex flex-row justify-between items-center relative pt-2'>
                  <div className='flex items-center flex-row gap-2'>
                    <p className='font-poppinsMedium'>Overview</p>
                    <p className='text-success'>(+5) more </p>
                    <p className='text-gray-400'>in 2024 </p>
                  </div>
                  {/* Sort */}
                  <div className='text-sm absolute bg-[#F2F2F2] text-black right-3 rounded-md z-10 '>
                    <Picker
                      onChange={handleFilterChange}
                      options={options}
                      placeholder={'Monthly'}
                    />
                  </div>
                </div>
                <div className=''>
                  <BarChart
                    className='mt-6 text-black'
                    data={filteredData}
                    index='name'
                    categories={['sales']}
                    colors={['amber']}
                    valueFormatter={valueFormatter}
                    yAxisWidth={60}
                    showLegend={false}
                    showAnimation={true}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Chards */}
          <div className='col-span-1'>
            {/* Card */}
            <div className='w-full bg-white rounded-lg h-auto p-6 text-xs'>
              <div className='flex flex-row justify-between items-center pb-2'>
                <div className='flex items-center flex-row gap-2'>
                  <p className='font-poppinsMedium text-lg'>Recent Payments</p>
                </div>
              </div>
              <Resolution products={resolutionData} />
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  margin-bottom: 3rem;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
  overflow-x: hidden;
`;

export default Accounting;

interface IOverviewCard {
  datasets: IOverviewCardProps[];
}
const OverviewCard = ({datasets}: IOverviewCard) => {
  return (
    <>
      {datasets.map((item, index) => (
        <div
          key={index}
          className='bg-white rounded-lg 2xl:w-[250px] lg:w-[250px] sm:w-[300px]  w-[250px] py-8 p-6 flex flex-col items-start gap-6 shadow-md h-[200px]'>
          {/* Image */}
          <div className='p-1 bg-[#EEEFFF] rounded-xl flex flex-row justify-center items-center text-primary'>
            {item.icon}
          </div>
          <div className='flex flex-col gap-4'>
            <p className='text-black font-poppinsMedium lg:text-sm text-xs'>
              {item.title}
            </p>
            {item.amount && (
              <p className='text-black font-poppinsSemibold'>
                ₦{numberFormat(item.amount)}
              </p>
            )}
            {item.value && (
              <p className='text-black font-poppinsSemibold'>
                {numberFormat(item.value)}
              </p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};
