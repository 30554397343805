/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import Pagination from '../../../components/ui/Pagination';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
// import {useEffect, useState} from 'react';
import {useAdmin} from '../../../app/hooks';
import {AdminServices} from '../../../redux/services/admin/admin';
// import {axiosError} from '../../../components/Helpers';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {addMonths} from 'date-fns';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';
import GiftCardItem from '../../../components/GiftCardItem';
import {axiosError} from '../../../components/Helpers';
import {GiftCard} from '../../../components/table/GiftTransctionTable';

const WishCardList = () => {
  // Query
  const [query, setQuery] = useState('');
  const keys = ['title'];
  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };
  // Sort --------------------
  const [isLoading, setIsLoading] = useState(false);
  const [giftCards, setGiftCards] = useState<GiftCard[]>([]);

  const navigate = useNavigate();
  const token = useAdmin().adminToken;

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-04-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  // Get Gift
  useEffect(() => {
    getGiftCards();
  }, []);

  const getGiftCards = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getAllGiftCards(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data.details;
        setGiftCards(res.rows);
        // console.log(res.rows);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  const searchData = search(giftCards);

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData: GiftCard[] = searchData.slice(
    firstPageIndex,
    lastPageIndex
  );

  // console.log(currentTableData);
  const totalPages = Math.ceil(searchData.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleEdit = (item: any) => {
    console.log('Edit clicked');
    localStorage.setItem('#card', JSON.stringify(item));
    navigate(`../admin/giftCards/edit/${item.id}`);
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {/* Confirm Delete Modal */}

        <Helmet>
          <meta charSet='utf-8' />
          <title>Wish Cards</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>WISH CARDS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => navigate('../admin/messages')} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-between gap-6 pt-6'>
          {/* Sort */}

          <div className='flex flex-row items-center  gap-6'>
            {/* Date Filter */}
            <DateRangePicker
              endDate={endDate}
              onEndDateChange={handleEndDateChange}
              onStartDateChange={handleStartDateChange}
              startDate={startDate}
            />
          </div>
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <div className='space-y-4'>
                {currentTableData.map((item, index) => (
                  <GiftCardItem
                    key={index}
                    productImg={item.productImg} // Replace with actual image path
                    title={item.title}
                    code={item.code}
                    amount={item.amount}
                    id={item.id}
                    onEdit={() => handleEdit(item)}
                    giftCards={giftCards}
                    setGiftCards={setGiftCards}
                  />
                ))}
              </div>
            )}

            {currentTableData.length === 0 && (
              <div className='text-center py-6'>
                <span className='text-xl text-primary font-semibold'>
                  No Data available
                </span>
              </div>
            )}
          </div>
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default WishCardList;
