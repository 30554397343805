import {api, fetchApi} from '../api';

const header = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// All Products

const allProducts = (token: string) => {
  return api.get(`product/all?`, header(token));
};
// All Products
const getProductsWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `product/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
// All with Pagination
interface IAllProduct {
  token: string;
  offset?: number | 0;
  pageSize?: number | 100;
}
const getProductWithPagination = ({token, offset, pageSize}: IAllProduct) => {
  return api.get(
    `product/all?offset=${offset}&limit=${pageSize}`,
    header(token)
  );
};
// All Buyers
const allBuyer = (token: string) => {
  return api.get('buyer/all', header(token));
};

// All Buyers
const getBuyersWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `buyer/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
// All Admin
const allAdmins = (token: string) => {
  return api.get('/admin/all', header(token));
};
// All Vendor
const allVendors = (token: string) => {
  return api.get('/vendor/all', header(token));
};
const allMessages = (token: string) => {
  return api.get('/contact/all', header(token));
};
// All Vendor
const getVendorsWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `vendor/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
// All Commissions
const getCommissionsWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `vendor/commissions?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
const deleteVendor = (token: string, vendorId: string, data: Object) => {
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `/vendor/action/${vendorId}`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};
const deactivateAdmin = (token: string, adminId: string, data: Object) => {
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `/admin/deactivate/${adminId}`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};
const deleteCoupon = (token: string, couponId: string, data: Object) => {
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `/coupon/action/${couponId}`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};
const deleteBuyer = (token: string, buyerId: string, data: Object) => {
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `/buyer/action/${buyerId}`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};

// Approve Products
const productApproval = (token: string, data: any) => {
  // return api.patch(`product/approval/:${productId}`, header(token));
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `product/approval`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};

// All with Pagination
interface IAllOrdersProduct {
  token: string;
  offset?: number | 0;
  pageSize?: number | 100;
}
const getOrdersWithPagination = ({
  token,
  offset,
  pageSize,
}: IAllOrdersProduct) => {
  return api.get(`order/all?offset=${offset}&limit=${pageSize}`, header(token));
};

const getOrders = (token: string) => {
  return api.get(`order/all`, header(token));
};
const getOrdersWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `order/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
const getCancelledOrders = (token: string) => {
  return api.get(`order/status/cancelled`, header(token));
};
const getSettlements = (token: string) => {
  return api.get(`settlement/all`, header(token));
};
const getSettlementsWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `settlement/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};

const getBusinessWallet = (token: string) => {
  return api.get(`wallet/business`, header(token));
};
const updateSettlements = (token: string, data: any) => {
  return api.patch(`settlement/update`, data, header(token));
};
const getTransactions = (token: string) => {
  return api.get(`transaction/all`, header(token));
};
const getTransactionWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `transaction/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};

// All with Pagination
interface IReviewProduct {
  token: string;
  offset?: number | 0;
  pageSize?: number | 100;
}
const getReviewWithPagination = ({token, offset, pageSize}: IReviewProduct) => {
  return api.get(`review?offset=${offset}&limit=${pageSize}`, header(token));
};

const getReviews = (token: string) => {
  return api.get(`review`, header(token));
};
const getReturns = (token: string) => {
  return api.get(`order/returns`, header(token));
};
const getReturnsWithDateRange = (
  token: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `order/returns?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};

const getCoupons = (token: string, startDate: any, endDate: any) => {
  return api.get(
    `coupon/all?startDate=${startDate}&endDate=${endDate}`,
    header(token)
  );
};
const singleOrder = (token: string, orderId: string) => {
  return api.get(`order/details/${orderId}`, header(token));
};
const updateOrder = (token: string, orderId: string, data: any) => {
  return api.patch(`order/update/${orderId}`, data, header(token));
};
const updateVendorOrder = (token: string, orderId: string, data: any) => {
  return api.patch(`order/${orderId}/ready-for-pickup`, data, header(token));
};
const cancelVendorOrder = (token: string, orderId: string, data: any) => {
  return api.patch(`order/${orderId}/cancel`, data, header(token));
};
const approveReview = (token: string, reviewId: string, data: Object) => {
  // return api.patch(`/review/${reviewId}`, header(token), data);
  return fetchApi({
    method: 'PATCH',
    body: data,
    url: `review/${reviewId}`,
    header: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  });
};
const rejectReview = (token: string, reviewId: string) => {
  return api.delete(`review/${reviewId}`, header(token));
};
const uploadCSVFile = (token: string, data: File) => {
  return api.post(`upload/payment`, data, header(token));
};
const createCoupon = (token: string, data: any) => {
  return api.post(`coupon/generate`, data, header(token));
};
const resetVendor = (token: string, vendorId: string, data: any) => {
  return api.patch(
    `admin/update_vendor_credential/${vendorId}`,
    data,
    header(token)
  );
};
const getAllGiftCards = (token: string) => {
  return api.get(`/gift/all`, header(token));
};
const getAllGiftTransactions = (token: string) => {
  return api.get(`/gift/history`, header(token));
};

export const AdminServices = {
  allProducts,
  productApproval,
  allVendors,
  deleteVendor,
  allBuyer,
  deleteBuyer,
  getOrders,
  singleOrder,
  updateOrder,
  approveReview,
  rejectReview,
  getReviews,
  getProductWithPagination,
  getReviewWithPagination,
  getOrdersWithPagination,
  uploadCSVFile,
  getTransactions,
  getSettlements,
  updateSettlements,
  getBusinessWallet,
  getReturns,
  resetVendor,
  createCoupon,
  getCoupons,
  deleteCoupon,
  updateVendorOrder,
  cancelVendorOrder,
  getCancelledOrders,
  getVendorsWithDateRange,
  getBuyersWithDateRange,
  getProductsWithDateRange,
  getOrdersWithDateRange,
  getReturnsWithDateRange,
  getTransactionWithDateRange,
  getSettlementsWithDateRange,
  allAdmins,
  deactivateAdmin,
  getCommissionsWithDateRange,
  getAllGiftCards,
  getAllGiftTransactions,
  allMessages,
};
