import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';

// Define the Contact type
export interface Contact {
  id: string | number;
  fullName: string;
  email: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

// Define the state type
export interface ContactState {
  contacts: Contact[];
}

// Retrieve contacts from localStorage, default to an empty array if not found
const storedContacts = localStorage.getItem('contactList');
const initialState: ContactState = {
  contacts: storedContacts ? JSON.parse(storedContacts) : [],
};

const ContactReducer = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    setContactsCredentials: (state, action: PayloadAction<Contact[]>) => {
      state.contacts = action.payload;
      localStorage.setItem('contactList', JSON.stringify(action.payload));
    },
  },
});

// Export actions
export const {setContactsCredentials} = ContactReducer.actions;

// Selector
export const selectContacts = (state: RootState) => state.contacts.contacts;

// Export reducer
export default ContactReducer.reducer;
