import {Notification} from 'iconsax-react';
import {COLORS} from '../../../../assets/color';
import {useContact} from '../../../../app/hooks';

interface INotificationIcon {
  onClick: () => void;
}
const NotificationIcon = ({onClick}: INotificationIcon) => {
  const notification = useContact().contacts;
  return (
    <div
      onClick={onClick}
      className='flex justify-center items-center flex-row cursor-pointer'
      style={{
        backgroundColor: '#FFFFFF',
        width: 32,
        height: 32,
        borderRadius: 15,
      }}>
      <div onClick={() => ''} className='relative'>
        <Notification size={22} color={COLORS.primary} variant='Bold' />

        <div
          style={{
            backgroundColor: 'red',
            width: 14,
            height: 14,
            position: 'absolute',
            top: -2,
            right: 0,
            borderRadius: 7,
            color: 'white',
          }}
          className='flex flex-row justify-center items-center'>
          <p>{notification.length}</p>
        </div>
      </div>
    </div>
  );
};

export default NotificationIcon;
