/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {useEffect, useState} from 'react';
import Pagination from '../../../components/ui/Pagination';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {addMonths} from 'date-fns';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';
import GiftCardTransactionTable from '../../../components/table/GiftTransctionTable';
import {AdminServices} from '../../../redux/services/admin/admin';
import {useAdmin} from '../../../app/hooks';
import {axiosError} from '../../../components/Helpers';
import {useNavigate} from 'react-router-dom';

const GiftCardTransactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<any[]>([]);
  const navigate = useNavigate();

  const token = useAdmin().adminToken;
  // Query
  const [query, setQuery] = useState('');
  // Define keys to include both direct and nested keys
  const keys = [
    'orderedGifts.recipientEmail',
    'orderedGifts.code',
    'orderedGifts.recipientName',
    'title',
  ];

  // Function to access nested keys in objects
  const getValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const search = (data: any, query: string) => {
    return data?.filter((item: any) =>
      keys.some((key) => {
        const value =
          getValue(item, key) ||
          getValue(item.orderedGifts[0], key.split('.').slice(1).join('.'));
        return value
          ? value.toString().toLowerCase().includes(query.toLowerCase())
          : false;
      })
    );
  };

  // Get Transactions
  useEffect(() => {
    getGiftCards();
  }, []);

  const getGiftCards = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getAllGiftTransactions(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data.details;
        setTransactions(res.rows);
        // console.log(res.rows);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-06-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  // Pagination
  const PageSize = 8;
  const [currentPage, setCurrentPage] = useState(1);

  // Step 1: Apply search on transactions first
  const filteredTransactions = search(transactions, query);

  // Step 2: Flatten the filtered transactions
  const orderGifts = filteredTransactions.flatMap((item: any) =>
    item.orderedGifts.map((gift: any) => ({
      ...gift,
      transaction: item, // Keep reference to transaction for images, amounts, etc.
    }))
  );

  // Search logic (ensure this filters the flattened `orderGifts` properly)

  // Step 3: Apply pagination on the filtered & flattened data
  const totalPages = Math.max(1, Math.ceil(orderGifts.length / PageSize));
  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = orderGifts.slice(firstPageIndex, lastPageIndex);
  // Handle page change
  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>GiftCard Transactions</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>GIFTCARD TRANSACTION</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => navigate('../admin/messages')} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-end gap-6 pt-4'>
          {/* Date Filter */}

          <DateRangePicker
            endDate={endDate}
            onEndDateChange={handleEndDateChange}
            onStartDateChange={handleStartDateChange}
            startDate={startDate}
          />
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <GiftCardTransactionTable transactions={currentTableData} />
            )}
          </div>
        </div>

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default GiftCardTransactions;
