import React, {useState} from 'react';
import styled from 'styled-components';
import {COLORS} from '../../../assets/color';
import {Helmet} from 'react-helmet';
import {ArrowLeft} from 'iconsax-react';
import {motion} from 'framer-motion';
import {useNavigate, useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {useAdmin} from '../../../app/hooks';
import {axiosError} from '../../../components/Helpers';
import Spinner from '../../../components/spinner/Spinner';

const EditGiftCard = () => {
  const {id} = useParams();
  const singleCard = JSON.parse(localStorage.getItem('#card') || '{}');
  const [selectedImage, setSelectedImage] = useState(singleCard);
  const [amount, setAmount] = useState(singleCard.amount);
  const [title, setTitle] = useState(singleCard.title);
  const [message, setMessage] = useState(singleCard.description);
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  const clearInputs = () => {
    setAmount('');
    setTitle('Christmas');
    setMessage('');
    setSelectedImage(null);
  };

  const token = useAdmin().adminToken;

  const handleSubmit = async () => {
    try {
      setIsUploading(true);
      const productResData = {
        title: title,
        description: message,
      };
      // Proceed to upload the product with the updated state
      const productRes = await VendorServices.updateGiftCard(
        token,
        id,
        productResData
      );

      setIsUploading(false);

      if (productRes.data.status === 'success') {
        toast.success(
          productRes.data.data.message || 'Card successfully updated.'
        );
        clearInputs();
        navigate('../admin/giftCards/list');
      }
    } catch (error: any) {
      axiosError(error.response);
      // console.log(error);
      setIsUploading(false);
    }
  };
  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {isUploading && <Spinner />}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Edit GiftCard</title>
        </Helmet>
        <motion.div
          whileHover={{scale: 1.1}}
          className='w-24 text-center cursor-pointer'
          onClick={() => navigate(-1)}>
          <ArrowLeft size={28} variant='Linear' />
        </motion.div>
        <ProductBar>
          <Title>
            <h2>EDIT GIFTCARD</h2>
          </Title>
        </ProductBar>
        <div className='flex gap-10 p-8 '>
          {/* Left Section: Giftcard Preview and Image Selection */}
          <div className='flex flex-col w-1/2'>
            <div className='relative w-full mx-auto'>
              <img
                src={selectedImage.productImg}
                alt='Gift Card'
                className='w-full h-auto rounded-xl shadow-lg'
              />
            </div>
          </div>

          {/* Right Section: Amount & Details */}
          <div className='w-1/2 space-y-6'>
            <h2 className='text-2xl font-bold'>{selectedImage.title}</h2>
            <p className='text-gray-400'>
              Enter the amount and details for the gift card.
            </p>

            {/* Amount Input */}
            <div>
              <label className='block text-sm text-gray-400'>
                Enter Amount
              </label>
              <input
                type='number'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className='w-full p-2 b rounded-lg focus:outline-none'
                readOnly
              />
            </div>

            {/* Total Display */}
            <p className='text-xl font-bold text-red-500'>
              Total: NGN {amount ? Number(amount).toLocaleString() : '0'}
            </p>

            {/* Details Section */}
            <div className='border-t border-gray-700 pt-4'>
              <label className='block text-sm text-gray-400'>Title</label>
              <input
                type='text'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className='w-full p-2 b rounded-lg focus:outline-none '
              />
            </div>
            <div>
              <label className='block text-sm text-gray-400'>
                Enter your message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className='w-full p-2 b rounded-lg h-48 focus:outline-none '
              />
            </div>

            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              className='w-full bg-orange-500 p-3 rounded-lg font-bold text-white hover:bg-orange-600 transition'>
              Update Giftcard
            </button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default EditGiftCard;
