import {FaTrashAlt} from 'react-icons/fa';
import {FiEdit2} from 'react-icons/fi';
import {motion} from 'framer-motion';
import ConfirmDelete from './modal/ConfirmDelete';
import {useState} from 'react';
import {GiftCard} from './table/GiftTransctionTable';
import {toast} from 'react-toastify';
import {axiosError} from './Helpers';
import {VendorServices} from '../redux/services/vendor/vendor';
import {useAdmin} from '../app/hooks';
import Spinner from './spinner/Spinner';

interface GiftCardItemProps {
  productImg: string;
  title: string;
  code: string;
  onEdit?: () => void;
  id: string;
  giftCards: GiftCard[];
  setGiftCards: React.Dispatch<any>;
  amount: any;
}

const GiftCardItem: React.FC<GiftCardItemProps> = ({
  productImg,
  title,
  id,
  onEdit,
  giftCards,
  setGiftCards,
  amount,
}) => {
  const [loading, setLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const token = useAdmin().adminToken;

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await VendorServices.deleteGiftCard(token, id);
      if (response.data.status === 'success') {
        const updatedGift = giftCards?.filter((p: any) => p.id !== id);
        setGiftCards(updatedGift);
        setLoading(false);

        // console.log(response.data);
        toast.success(response.data.data.message);
      }
    } catch (error: any) {
      axiosError(error.response);
      setLoading(false);

      console.log(error);
    }
  };
  return (
    <>
      {loading && <Spinner />}
      {showDelete && (
        <ConfirmDelete setIsOpen={setShowDelete} deleHandler={handleDelete} />
      )}
      <motion.div
        whileHover={{scale: 1.02}}
        className='bg-white rounded-xl shadow-sm flex items-center p-4'>
        <img
          src={productImg}
          alt='Gift Card'
          className='w-24 h-16 rounded-lg'
        />
        <div className='ml-4 flex-1'>
          <h3 className='font-bold text-lg'>{title}</h3>
          <p className='text-gray-500 text-sm'>N {amount}</p>
        </div>
        <div className='flex items-center gap-3'>
          <FiEdit2
            className='text-gray-300 text-xl cursor-pointer'
            onClick={onEdit}
          />
          <FaTrashAlt
            className='text-red-500 text-xl cursor-pointer'
            onClick={() => setShowDelete(true)}
          />
        </div>
      </motion.div>
    </>
  );
};

export default GiftCardItem;
