/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import ViewVendor from '../../pages/dashboard/admin/modal/ViewVendor';
import {ICommission} from '../../pages/dashboard/admin/Commissions';

interface IFlattenMap {
  vendorName: string;
  id: string;
  name: string;
  price: any;
  commission?: any;
  category?: any;
}
interface IVendorTable {
  commissions: IFlattenMap[];
}
const CommissionTable = ({commissions}: IVendorTable) => {
  const [showView, setShowView] = useState(false);

  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewVendor setIsOpen={setShowView} />
        </div>
      )}
      <div>
        <table className='mt-2 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10'>Product ID</th>
              <th className='w-10'>Product Name</th>
              <th className='w-10'>Vendor Name</th>
              <th className='w-10'>Category</th>
              <th className='w-10'>Price</th>
              <th className='w-10 rounded-tr-md '>Commission</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-orange-100'>
            {commissions.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {commissions.map((product) => (
                <tr className='overflow-x-auto h-14' key={product.id}>
                  <td className='w-10 text-center'>{product.id}</td>
                  <td className='w-10 text-center'>
                    {product.name.length > 40
                      ? product.name.slice(0, 40) + '...'
                      : product.name}
                  </td>
                  <td className='w-10 text-center'>{product.vendorName}</td>
                  <td className='w-10 text-center'>{`${
                    product.category || 'N / A'
                  } `}</td>
                  {/* Add the category if needed */}
                  <td className='w-10 text-center'>{product.price}</td>
                  <td className='w-10 text-center'>{product.commission}</td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CommissionTable;
