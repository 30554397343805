import React, {useState} from 'react';
import styled from 'styled-components';
import {COLORS} from '../../../assets/color';
import {Helmet} from 'react-helmet';
import {birthdayGift, valentinGift, weddingGift} from '../../../assets/images';
import {ArrowLeft} from 'iconsax-react';
import {motion} from 'framer-motion';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {useAdmin} from '../../../app/hooks';
import {axiosError} from '../../../components/Helpers';
import Spinner from '../../../components/spinner/Spinner';
import {generateImageWithAmount} from '../../../components/GenerateImageWithAmount';

const giftCardImages = [
  {id: 1, src: valentinGift, title: 'Happy Valentine'},
  {id: 2, src: birthdayGift, title: 'Happy Birthday'},
  {id: 3, src: valentinGift, title: 'Love'},
  {id: 4, src: birthdayGift, title: 'Thank You'},
  {id: 5, src: weddingGift, title: 'Wedding'},
];

const GiftCardCreator = () => {
  const [selectedImage, setSelectedImage] = useState(giftCardImages[0]);
  const [amount, setAmount] = useState('');
  const [title, setTitle] = useState('Christmas');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = useState(false);

  const clearInputs = () => {
    setAmount('');
    setTitle('Christmas');
    setMessage('');
    setSelectedImage(giftCardImages[0]);
  };

  const token = useAdmin().adminToken;

  // const handleSubmit = async () => {
  //   try {
  //     setIsUploading(true);

  //     if (!amount || !title || !message) {
  //       toast.error('All fields are required!');
  //       setIsUploading(false);
  //     }
  //     // Loading

  //     // Submit additional images and get the URLs
  //     const image = selectedImage.src;

  //     if (!image) {
  //       console.error('No images selected for upload.');
  //       return;
  //     }

  //     const data = {
  //       image: selectedImage.src,
  //     };

  //     const imageRes = await VendorServices.imageUpload(token, data);

  //     if (imageRes.data.status === 'success') {
  //       // const url = imageRes.data.data.url;
  //       // Log the URLs to ensure they are correct
  //       // console.log('Image URLs:', urls);
  //       // toast.success(imageRes.data.data.message);
  //     } else {
  //       toast.error(imageRes.data.data.message);
  //       setIsUploading(false);
  //       return; // Exit the function if image upload fails
  //     }
  //     try {
  //       const productResData = {
  //         title: title,
  //         amount: amount,
  //         description: message,
  //         productImg: imageRes.data.data.url,
  //       };
  //       // Proceed to upload the product with the updated state
  //       const productRes = await VendorServices.createGiftCard(
  //         token,
  //         productResData
  //       );

  //       setIsUploading(false);

  //       if (productRes.data.status === 'success') {
  //         toast.success(
  //           productRes.data.data.message || 'Card successfully Created.'
  //         );
  //         clearInputs();
  //         navigate(-1);
  //       }
  //     } catch (error: any) {
  //       axiosError(error.response);
  //       // console.log(error);
  //       setIsUploading(false);
  //     }
  //   } catch (error: any) {
  //     console.log(error);
  //     axiosError(error.response);
  //     setIsUploading(false);
  //   }
  // };

  const handleSubmit = async () => {
    try {
      setIsUploading(true);

      if (!amount || !title || !message) {
        toast.error('All fields are required!');
        setIsUploading(false);
        return;
      }

      // Generate the image with amount
      const finalImageBlob = await generateImageWithAmount(
        selectedImage.src,
        amount
      );

      if (!finalImageBlob) {
        toast.error('Failed to generate image.');
        setIsUploading(false);
        return;
      }

      // Convert Blob to FormData
      const formData = new FormData();
      formData.append('image', finalImageBlob, 'giftcard.png');

      // Upload image
      const imageRes = await VendorServices.imageUpload(token, formData);

      if (imageRes.data.status === 'success') {
        const productResData = {
          title: title,
          amount: amount,
          description: message,
          productImg: imageRes.data.data.url,
        };

        // Send product data to backend
        const productRes = await VendorServices.createGiftCard(
          token,
          productResData
        );

        setIsUploading(false);
        if (productRes.data.status === 'success') {
          toast.success(
            productRes.data.data.message || 'Card successfully Created.'
          );
          clearInputs();
          navigate(-1);
        }
      } else {
        toast.error(imageRes.data.data.message);
        setIsUploading(false);
      }
    } catch (error: any) {
      console.error(error);
      axiosError(error.response);
      setIsUploading(false);
    }
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {isUploading && <Spinner />}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Create GiftCard</title>
        </Helmet>
        <motion.div
          whileHover={{scale: 1.1}}
          className='w-24 text-center cursor-pointer'
          onClick={() => navigate(-1)}>
          <ArrowLeft size={28} variant='Linear' />
        </motion.div>
        <ProductBar>
          <Title>
            <h2>CREATE GIFTCARD</h2>
          </Title>
        </ProductBar>
        <div className='flex gap-10 p-8 '>
          {/* Left Section: Giftcard Preview and Image Selection */}
          <div className='flex flex-col w-1/2'>
            <div className='relative w-full mx-auto'>
              <img
                src={selectedImage.src}
                alt='Gift Card'
                className='w-full h-auto rounded-xl shadow-lg'
              />
              <p className='absolute bottom-6 right-4 px-3 py-1 rounded text-white text-2xl font-poppinsSemibold'>
                NGN {amount ? Number(amount).toLocaleString() : '0'}
              </p>
            </div>

            {/* Image Selection */}
            <div className='flex gap-3 mt-5'>
              {giftCardImages.map((img, index) => (
                <img
                  key={index}
                  src={img.src}
                  alt={`Gift ${index}`}
                  className={`w-16 h-16 rounded-lg cursor-pointer border-2 transition-all ${
                    selectedImage === img
                      ? 'border-orange-500'
                      : 'border-transparent'
                  }`}
                  onClick={() => setSelectedImage(img)}
                />
              ))}
            </div>
          </div>

          {/* Right Section: Amount & Details */}
          <div className='w-1/2 space-y-6'>
            <h2 className='text-2xl font-bold'>{selectedImage.title}</h2>
            <p className='text-gray-400'>
              Enter the amount and details for the gift card.
            </p>

            {/* Amount Input */}
            <div>
              <label className='block text-sm text-gray-400'>
                Enter Amount
              </label>
              <input
                type='number'
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className='w-full p-2 b rounded-lg focus:outline-none'
              />
            </div>

            {/* Total Display */}
            <p className='text-xl font-bold text-red-500'>
              Total: NGN {amount ? Number(amount).toLocaleString() : '0'}
            </p>

            {/* Details Section */}
            <div className='border-t border-gray-700 pt-4'>
              <label className='block text-sm text-gray-400'>Title</label>
              <input
                type='text'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className='w-full p-2 b rounded-lg focus:outline-none '
              />
            </div>
            <div>
              <label className='block text-sm text-gray-400'>
                Enter your message
              </label>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className='w-full p-2 b rounded-lg h-48 focus:outline-none '
              />
            </div>

            {/* Submit Button */}
            <button
              onClick={handleSubmit}
              className='w-full bg-orange-500 p-3 rounded-lg font-bold text-white hover:bg-orange-600 transition'>
              Add Giftcard
            </button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default GiftCardCreator;
