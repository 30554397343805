/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react';
import styled from 'styled-components';
import {BiSearch, BiSort} from 'react-icons/bi';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {useNavigate} from 'react-router-dom';
import {COLORS} from '../../../assets/color';
import {useContact, useVendor} from '../../../app/hooks';
import {Helmet} from 'react-helmet';
import InboxCard from '../../../components/dashboard/InboxCard';
import {inboxData} from '../../../components/dashboard/inbox';
import Pagination from '../../../components/ui/Pagination';
import moment from 'moment';
import {Contact} from '../../../redux/slices/contact/contactSlice';
import ContactMessagesDetails from './ContactMessagesDetails';

const ContactMessages = () => {
  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  const keys = ['fullName', 'email', 'message'];
  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };
  const notifications = useContact().contacts;

  const [sortingOption, setSortingOption] =
    useState<keyof Contact>('createdAt');
  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const searchData = search(notifications);

  const sortedData = [...searchData].sort((a, b) => {
    // Use the selected sorting option
    const sortOrder = isAscending ? 1 : -1;
    const aValue = a[sortingOption];
    const bValue = b[sortingOption];

    // Compare values based on the sorting order
    if (aValue < bValue) return -1 * sortOrder;
    if (aValue > bValue) return 1 * sortOrder;
    return 0;
  });

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = sortedData?.slice(firstPageIndex, lastPageIndex);

  // console.log(currentTableData);
  const totalPages = Math.ceil(sortedData?.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  // onViewMessage
  const viewHandler = async (id: any) => {
    notifications.filter((t: any) => t.id === id);
  };
  const [showView, setShowView] = useState(false);

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Message</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>Messages</h2>
          </Title>
          <div className='sm:flex flex-row items-center gap-4 justify-end hidden'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => navigate('../admin/messages')} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Body Start here */}
        {/* Body Start here */}
        <div className='py-4 w-full'>
          {showView && (
            <div className='z-50'>
              <ContactMessagesDetails setIsOpen={setShowView} />
            </div>
          )}
          <table className=' mt-4 w-full overflow-scroll text-sm table-fixed border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
            <thead>
              <tr className='h-[3.125rem] text-white'>
                <th
                  className=' rounded-tl-md cursor-pointer text-center'
                  onClick={() => handleSortingOptionChange('fullName')}>
                  <span className='flex flex-row gap-1 items-center justify-center'>
                    <span> Full Name</span>
                    <BiSort size={18} color='white' />
                  </span>
                </th>
                <th
                  className=' rounded-tl-md cursor-pointer text-center'
                  onClick={() => handleSortingOptionChange('email')}>
                  <span className='flex flex-row gap-1 items-center justify-center'>
                    <span> Email</span>
                    <BiSort size={18} color='white' />
                  </span>
                </th>

                <th className=''>Message</th>
                <th
                  className=' cursor-pointer text-center'
                  onClick={() => handleSortingOptionChange('createdAt')}>
                  <span className='flex flex-row gap-1 items-center justify-center'>
                    <span> Date</span>
                    <BiSort size={18} color='white' />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
              {currentTableData?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={7}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {currentTableData?.map((notification) => {
                  return (
                    <tr
                      className='overflow-x-auto font-medium text-[#6C757D] py-2'
                      key={notification.id}>
                      <td className=' text-center'>{notification?.fullName}</td>

                      <td className=' text-center'>{notification.email}</td>
                      <td className='w-60 text-center h-full'>
                        <div className='w-full h-full mx-auto p-2 whitespace-normal flex items-center justify-center'>
                          <span className='break-words'>
                            {notification.message}
                          </span>
                        </div>
                      </td>

                      <td className=' text-center'>
                        {moment(notification.createdAt).format('ll')}
                      </td>
                      <td className='w-16'>
                        <div className='flex flex-row justify-evenly items-center h-10'>
                          {/* View  */}
                          <span
                            onClick={() => {
                              viewHandler(notification.id);
                              localStorage.setItem(
                                '#sglNot',
                                JSON.stringify(notification)
                              );
                              setShowView(true);
                            }}
                            className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
                            View
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>

          {/* Pagination */}
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default ContactMessages;

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
