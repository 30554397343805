import React, {ChangeEvent, useState} from 'react';
import styles from '../../../../assets/styles/modals/ConfirmModal.module.css';
import {PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {useProduct} from '../../../../app/hooks';
import moment from 'moment';
import Slider from '../../../../components/ui/Slider';
import {toast} from 'react-toastify';

interface IModal {
  handleCloseModal: () => void;
}

const ViewProduct = ({handleCloseModal}: IModal) => {
  const product = useProduct().product;
  // console.log(product);
  const [formData, setFormData] = useState<any>({
    additionalImg: product?.additionalImg,
    approvedBy: product?.approvedBy,
    approvedDate: product?.approvedDate,
    brand: product?.brand,
    category: product?.category,
    description: product?.description,
    discount: product?.discount,
    id: product?.id,
    location: product?.location,
    name: product?.name,
    price: product?.price,
    productImg: product?.productImg,
    quantity: product?.quantity,
    productStatus: product?.status,
    subCategory: product?.subCategory,
    vendor: product?.vendor,
    minimumStock: product?.minimumStock,
    warranty: product?.warranty,
    dimension: product?.dimension,
    volume: product?.volume,
    weight: product?.weight,
    count: product?.count,
    commission: product?.commission,
    color: product?.color,
    size: product?.size,
    vendorMail: product?.vendorMail,
    vendorBusinessName: product?.businessName,
    shipFromAbroad: product?.shipFromAbroad,
    condition: product?.condition,
    bulky: false,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    if (checked || !checked) {
      toast.success('Product Updated!');
    }
  };

  const images = product?.additionalImg;
  return (
    <div>
      <div className={styles.productViewBG} />
      <div className={styles.productCentered}>
        <div className={styles.productViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-6 relative'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={handleCloseModal}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex flex-row items-center justify-center px-16 pb-4'>
                {product.productImg ? (
                  <div className='w-[400px] h-[400px] text-center object-cover'>
                    <img
                      src={product.productImg}
                      alt='prdIm'
                      className='object-contain h-full w-full'
                    />
                  </div>
                ) : (
                  <>
                    {images && (
                      <div className='w-[400px] h-[400px] text-center object-cover'>
                        <Slider slideImage={images} />
                      </div>
                    )}
                  </>
                )}
                {/* Additional Images */}
              </div>
              <div className=''>
                <span className='font-bold  text-lg text-[#2E3ECE] w-full '>
                  PRODUCT DETAILS
                </span>
              </div>
              {product.shipFromAbroad && (
                <div className='flex justify-center mt-[2rem]'>
                  <span className='p-2 text-xs font-semibold text-white bg-blue-600 rounded-xl'>
                    Shipped from Abroad
                  </span>
                </div>
              )}
              <div className='mt-[1rem]'>
                <div className='flex items-center justify-center flex-row gap-2'>
                  <input
                    type='checkbox'
                    checked={formData.bulky}
                    onChange={handleInputChange}
                    name='bulky'
                  />
                  <span className=''>The Product is bulky?</span>
                </div>
              </div>
              <div className='grid xl:grid-cols-3  md:grid-cols-2 grid-cols-1 place-items-baseline justify-items-center w-[80%] m-auto space-y-[2rem] gap-6'>
                <div className='w-full'>
                  <Input
                    label='PRODUCT ID'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    // name='category'
                    valueField={product?.id || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='VENDOR BUSINESS NAME'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    // name='category'
                    valueField={product?.businessName || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='VENDOR EMAIL'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    // name='category'
                    valueField={product?.vendorMail || ''}
                  />
                </div>

                <div className='w-full'>
                  <Input
                    label='CATEGORY'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    // name='category'
                    valueField={formData.category || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='SUB CATEGORY'
                    icon={PencilSm}
                    pattern=''
                    handleChange={(e: any) => handleInputChange(e)}
                    // name='subCategory'
                    valueField={formData.subCategory || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='PRODUCT NAME'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='name'
                    valueField={formData.name}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='BRAND NAME'
                    icon={PencilSm}
                    pattern=''
                    // name='brand'
                    handleChange={handleInputChange}
                    valueField={formData.brand || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='PRICE'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='price'
                    valueField={formData.price}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='APPROVED BY'
                    icon={PencilSm}
                    pattern=''
                    // name='approvedBy'
                    handleChange={handleInputChange}
                    valueField={formData.approvedBy || ''}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='APPROVED DATE'
                    icon={PencilSm}
                    pattern=''
                    // name='approvedDate'
                    handleChange={handleInputChange}
                    valueField={
                      moment(formData.approvedDate).format('lll') || ''
                    }
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='LOCATION'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='location'
                    valueField={formData.location}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='WARRANTY'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='name'
                    valueField={formData.warranty}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label='NUMBER IN STOCK'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='quantity'
                    valueField={formData.quantity}
                  />
                </div>

                <div className='w-full'>
                  <Input
                    label='DISCOUNT'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    // name='discount'
                    valueField={formData.discount}
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label={`Color`}
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='color'
                    valueField={formData.color || ''}
                    placeholder=''
                  />
                </div>
                <div className='w-full'>
                  <Input
                    label={`Size`}
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='size'
                    valueField={formData.size || ''}
                    placeholder=''
                  />
                </div>
                <div className='w-full'>
                  {/* Length && Width &  ... */}
                  <Input
                    label={`Dimension`}
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='dimension'
                    valueField={
                      formData.dimension?.replace(/"|\(.*?\)/g, '') || ''
                    }
                    // required
                    placeholder='40'
                  />
                </div>
                <div className=' w-full'>
                  {/* Length && Width &  ... */}

                  <Input
                    label='Volume'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='volume'
                    valueField={formData.volume?.replace(/\(.*?\)/g, '') || ''}
                    // required
                    placeholder='5'
                  />
                </div>
                <div className=' w-full'>
                  {/* Length && Width &  ... */}

                  <Input
                    label='Weight'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='weight'
                    valueField={formData.weight?.replace(/\(.*?\)/g, '') || ''}
                    // required
                    placeholder='5'
                  />
                </div>
                <div className='w-full'>
                  {/* Length && Width &  ... */}

                  <Input
                    label='Count'
                    icon={PencilSm}
                    pattern=''
                    handleChange={handleInputChange}
                    name='count'
                    valueField={formData.count?.replace(/\(.*?\)/g, '') || ''}
                    // required
                    placeholder='5'
                  />
                </div>
                <div className='w-full flex flex-col items-start'>
                  <span className='text-[#9A9AAA] font-medium pb-2'>
                    PRODUCT DESCRIPTION
                    <span className='text-red-500 text-base pl-1'>*</span>
                  </span>
                  <div className='w-full rounded-md border-[2px] border-gray-400 px-3'>
                    <textarea
                      className='outline-none w-full h-full p-3 font-light text-xs'
                      name='description'
                      value={formData.description}
                      placeholder=''
                      cols={10}
                      rows={10}
                      style={{
                        height: '100%',
                        fontFamily: 'montserrat',
                      }}
                      onChange={(e: any) => handleInputChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
