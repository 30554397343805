/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {useAppDispatch} from '../../app/hooks';
import {IVendor} from '../interfaces/vendors';
import {setSingleAdminVendor} from '../../redux/slices/vendor/adminVendorSlice';
import {DefaultImage} from '../../assets/images';
import Spinner from '../spinner/Spinner';
import ResetVendor from '../../pages/dashboard/admin/modal/ResetVendorForm';

interface IVendorTable {
  vendors: IVendor[];
  handleReset: (id: string, vendorEmail: string) => void;
  isActionLoading: boolean;
  showView: boolean;
  setShowView: React.Dispatch<React.SetStateAction<boolean>>;
}
const VendorUpdateTable = ({
  vendors,
  isActionLoading,
  handleReset,
  setShowView,
  showView,
}: IVendorTable) => {
  const dispatch = useAppDispatch();

  // onViewProduct
  const viewHandler = async (id: any) => {
    vendors.filter((t: any) => t.id === id);
  };

  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ResetVendor
            setShowView={setShowView}
            handleReset={handleReset}
            isActionLoading={isActionLoading}
          />
        </div>
      )}
      <div>
        <table className=' mt-2 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-16 rounded-tl-md'>Image</th>
              <th className='w-16'>Vendor ID</th>
              <th className='w-16'>First Name</th>
              <th className='w-16'>Last Name</th>
              <th className='w-16'>Email</th>
              <th className='w-16'>Phone</th>
              <th className='w-16 rounded-tr-md '>Action</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {vendors.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {vendors.slice(0, 10).map((vendor: IVendor) => {
                return (
                  <tr className='overflow-x-auto' key={vendor.id}>
                    <td className='h-20'>
                      <div className='flex justify-center'>
                        <div className='lg:w-16 w-8 lg:h-16 h-8 rounded-full self-center'>
                          {vendor.vendorImg !== null ? (
                            <img
                              src={vendor.vendorImg}
                              alt=''
                              className='object-cover w-full rounded-full h-full'
                            />
                          ) : (
                            <img
                              src={DefaultImage}
                              alt='profile-def'
                              className='rounded-full w-full h-full object-cover'
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>{vendor.id}</td>
                    <td className='w-10 text-center'>{vendor.firstName}</td>
                    <td className='w-10 text-center'>{vendor.lastName}</td>
                    <td className='w-10 text-center'>{vendor.email}</td>
                    <td className='w-10 text-center'>{vendor.phone}</td>
                    {/* Status */}

                    {/* Action */}
                    <td className='w-16'>
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {/* View  */}
                        <span
                          onClick={() => {
                            viewHandler(vendor.id);
                            dispatch(setSingleAdminVendor(vendor));
                            setShowView(true);
                          }}
                          className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
                          Reset
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VendorUpdateTable;
