import {CloseCircle} from 'iconsax-react';
import styles from '../../../assets/styles/modals/ConfirmModal.module.css';
interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ContactMessagesDetails = ({setIsOpen}: IModal) => {
  const notificationItem = JSON.parse(localStorage.getItem('#sglNot') || '{}');

  // const isActive = String(vendor.isActive);
  //   console.log(isUpdated);
  return (
    <div>
      <div className={styles.productViewBG} onClick={() => setIsOpen(true)} />
      <div className={styles.productCentered}>
        <div className={styles.notificationViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-10 relative'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='w-full items-center justify-center h-full flex flex-col'>
                <h5 className='text-center text-lg'>
                  {notificationItem?.message}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMessagesDetails;
