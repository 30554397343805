import {Outlet} from 'react-router-dom';
import {useEffect, useState} from 'react';
import Navbar from '../ui/Navbar';
import {navBarMenu} from '../../common/menuItems';
import BackToTopButton from '../ui/BackToTop';
import AdvertBar from '../ui/AdvertBar';
import Footer from '../Footer';
import {customerUrl} from '../../constant';

const VendorAuthLayout = () => {
  // Search
  const [searchQuery, setSearchQuery] = useState<string>('');

  const performSearch = () => {
    if (searchQuery.trim() !== '') {
      window.location.href = `${customerUrl}/products?search=${encodeURIComponent(
        searchQuery.trim()
      )}`;
      // setSearchQuery(''); // Uncomment if you want to clear the search query after navigation
    }
  };

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      performSearch();
    }
  };

  const handleIconClick = () => {
    performSearch();
  };

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isTop = currentScrollPos < 10; // Change 100 to your desired scroll position
      setIsScrolled(!isTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <div className='w-full font-poppinsRegular'>
        {/* Advert */}
        {isOpen && <AdvertBar setIsOpen={setIsOpen} />}
        {/* Body */}
        <div
          className={`bg-white sm:h-[6rem] sticky top-0 z-50 transition-all duration-300 shadow-sm${
            isScrolled ? 'shadow-md ' : ''
          }`}>
          <div className='lg:container lg:mx-auto lg:px-20 px-4 z-50'>
            <Navbar
              setQuery={setSearchQuery}
              NavItem={navBarMenu}
              handleSearch={handleSearch}
              searchQuery={searchQuery}
              handleIconClick={handleIconClick}
            />
          </div>
        </div>
        <div className='min-h-[calc(100vh-436px)]'>
          <Outlet />
        </div>

        {/* Back to top */}
        <BackToTopButton />

        {/* Footer */}
        <div className='w-full lg:h-[436px] h-full bg-primary '>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default VendorAuthLayout;
